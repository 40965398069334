import React, { useState } from 'react';
import PropertyDetails from './PropertyDetails';

import TypeCarousel from './TypeCarousel';
import Carousel from './Carousel';
import About from './About';
import Category from './Category';

function Homepage() {
    return (
        <>
            <Carousel />
            <About />
            <Category />
            {/* NEW ABCDEFG */}
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5 align-items-center" style={{borderRadius:'1%',border:'25px solid black'}}>
                        <div class="col-lg-6">
                            <div class="about-img position-relative overflow-hidden p-5 pe-0">
                                <img class="img-fluid w-100" src="/assets/Images/Property/premium-property.jpg" style={{borderRadius:'1%',border:'5px solid black'}}/>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-5">
                            <h1 class="mb-4">#1 Place To Find The Perfect Property</h1>
                            <p class="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p>
                            <p><i class="fa fa-check text-primary me-3"></i>Tempor erat elitr rebum at clita</p>
                            <p><i class="fa fa-check text-primary me-3"></i>Aliqu diam amet diam et eos</p>
                            <p><i class="fa fa-check text-primary me-3"></i>Clita duo justo magna dolore erat amet</p>
                            <a class="btn btn-primary py-3 px-5 mt-3" href="">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* NEW ABCDEFG */}
            <TypeCarousel />
            <PropertyDetails />
            {/* PROPERTY DETAILS ENDING  */}
            {/* Marketing Section */}
            <div className="container marketing">
                <div className="row justify-content-center">
                    <div className="col-md-12 heading-section text-center mb-5">
                        <span className="subheading">Our Services</span>
                        <h2 className="mb-2">Exclusive Real Estate Services</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card">
                            <img src="/assets/Images/Property/services.jpg" className="card-img-top" alt="Service 1" />
                            <div className="card-body">
                                <h5 className="card-title">Property Buying</h5>
                                <p className="card-text">Expert guidance on purchasing properties, from finding the perfect match to closing the deal.</p>
                            </div>
                            <div className="card-body">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <img src="/assets/Images/Property/services.jpg" className="card-img-top" alt="Service 2" />
                            <div className="card-body">
                                <h5 className="card-title">Property Selling</h5>
                                <p className="card-text">Professional services to help you sell your property quickly and at the best price.</p>
                            </div>
                            <div className="card-body">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <img src="/assets/Images/Property/services.jpg" className="card-img-top" alt="Service 3" />
                            <div className="card-body">
                                <h5 className="card-title">Property Management</h5>
                                <p className="card-text">Comprehensive property management services to keep your investments in top shape.</p>
                            </div>
                            <div className="card-body">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="featurette-divider mb-5" />
                <div className="row justify-content-center">
                    <div className="col-md-12 heading-section text-center mb-5">
                        <span className="subheading">Top Locations</span>
                        <h2 className="mb-2">Discover the Best Real Estate Hotspots</h2>
                        <p className="lead">Explore our curated list of top locations for buying or renting your dream property. Find the perfect neighborhood that fits your lifestyle and preferences.</p>
                    </div>
                </div>

                <div className="row featurette mt-3">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">Premium Properties in Top Locations. <span className="text-muted">Experience Luxury Living.</span></h2>
                        <p className="lead">Explore our selection of high-end properties situated in the most desirable locations. Find your dream home today.</p>
                    </div>
                    <div className="col-md-5">
                        <img src="/assets/Images/Property/premium-property.jpg" className="d-block w-100" alt="Premium Property" />
                    </div>
                </div>

                <hr className="featurette-divider" />

                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading">Affordable Options for Every Budget. <span className="text-muted">Quality and Value.</span></h2>
                        <p className="lead">We offer a range of affordable housing options that provide excellent value for your money. Find the perfect fit for your budget.</p>
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img src="/assets/Images/Property/property-search.jpg" className="d-block w-100" alt="Affordable Property" />
                    </div>
                </div>

                <hr className="featurette-divider" />

                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">Exclusive Deals and Offers. <span className="text-muted">Act Now!</span></h2>
                        <p className="lead">Check out our exclusive deals and special offers on properties. Don’t miss out on these limited-time opportunities!</p>
                    </div>
                    <div className="col-md-5">
                        <img src="/assets/Images/Property/premium-property.jpg" className="d-block w-100" alt="Exclusive Deals" />
                    </div>
                </div>

                <hr className="featurette-divider" />
            </div>
            {/* Custom Cards */}
            <div className="container px-4 py-5" id="custom-cards">
                <div className="row justify-content-center">
                    <div className="col-md-12 heading-section text-center mb-5">
                        <span className="subheading">Featured Listings</span>
                        <h2 className="mb-2">Find Properties in Your Area</h2>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
                    <div className="col">
                        <div className="card card-cover h-100 overflow-hidden  rounded-5 shadow-lg bg-card_img">
                            <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Modern Downtown Apartment</h2>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                    </li>
                                    <li className="d-flex align-items-center me-3">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>3 Beds</small>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>$1,200/month</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg bg-card_img">
                            <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Charming Family Home</h2>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                    </li>
                                    <li className="d-flex align-items-center me-3">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>4 Beds</small>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>$2,500/month</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg bg-card_img">
                            <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Spacious Suburban House</h2>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                    </li>
                                    <li className="d-flex align-items-center me-3">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>5 Beds</small>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>$3,000/month</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Homepage;
