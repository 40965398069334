import React, { useState } from 'react'
import PropertySearch from './PropertySearch';
const Carousel = () => {
    const [filter, setFilter] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    const handleFilterChange = (e) => setFilter(e.target.value);
    return (
        <>
            <div id="myCarousel" className="carousel slide mt-5 mb-5" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="1000">
                        <img src="/assets/Images/Property/property-search.jpg" className="d-block w-100 carousel-image" alt="Featured Property 1" />
                        <div className="row carousel-caption d-flex justify-content-center align-items-center">
                            <div className="col text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                {/* <h1>Luxury Homes in Prime Locations</h1>
                                <p>Discover the best properties in the most sought-after locations. Your dream home awaits.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">Explore Listings</a></p> */}
                                <h2 className="text-center mb-4">Find Your Dream Property</h2>
                                <form className="row g-3">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search properties..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-select" value={filter} onChange={handleFilterChange}>
                                            <option value="all">All Types</option>
                                            <option value="buy">Buy</option>
                                            <option value="rent">Rent</option>
                                            <option value="commercial">Commercial</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-primary w-100">Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src="/assets/Images/Property/property-search.jpg" className="d-block w-100 carousel-image" alt="Featured Property 2" />
                        {/* <div className="carousel-caption d-flex justify-content-center align-items-center">
                            <div className="text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                <h1>Affordable Housing Solutions</h1>
                                <p>Find affordable properties without compromising on quality. We have something for every budget.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">See Offers</a></p>
                            </div>
                        </div> */}
                        <div className="row carousel-caption d-flex justify-content-center align-items-center">
                            <div className="col text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                {/* <h1>Luxury Homes in Prime Locations</h1>
                                <p>Discover the best properties in the most sought-after locations. Your dream home awaits.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">Explore Listings</a></p> */}
                                <h2 className="text-center mb-4">Find Your Dream Property</h2>
                                <form className="row g-3">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search properties..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-select" value={filter} onChange={handleFilterChange}>
                                            <option value="all">All Types</option>
                                            <option value="buy">Buy</option>
                                            <option value="rent">Rent</option>
                                            <option value="commercial">Commercial</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-primary w-100">Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/assets/Images/Property/property-search.jpg" className="d-block w-100 carousel-image" alt="Featured Property 3" />
                        {/* <div className="carousel-caption d-flex justify-content-center align-items-center">
                            <div className="text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                <h1>Exclusive Real Estate Deals</h1>
                                <p>Get access to exclusive deals and offers on premium properties. Act fast to secure your investment.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">Browse Deals</a></p>
                            </div>
                        </div> */}
                        <PropertySearch />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}

export default Carousel