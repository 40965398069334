import React from 'react'

const Category = () => {
    return (
        <>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
                        <h1 class="mb-3">Property Types</h1>
                        <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid img-rounded" src="/assets/Images/Property/property-search.jpg" alt=" /Icon"/>
                                    </div>
                                    <h6>Apartment</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid"src="/assets/Images/Property/property-search.jpg" alt="Icon" />
                                    </div>
                                    <h6>Villa</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="Icon" />
                                    </div>
                                    <h6>Home</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="Icon" />
                                    </div>
                                    <h6>Office</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="Icon" />
                                    </div>
                                    <h6>Building</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="Icon"/>
                                    </div>
                                    <h6>Townhouse</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="Icon"/>
                                    </div>
                                    <h6>Shop</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <a class="cat-item d-block bg-light text-center rounded p-3" href="" style={{textDecoration:'none',border:'4px solid black'}}>
                                <div class="rounded p-4">
                                    <div class="icon mb-3">
                                        <img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="Icon"/>
                                    </div>
                                    <h6>Garage</h6>
                                    <span>123 Properties</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category