import React, { useState } from 'react';


const PropertySearch = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle search logic here
        console.log(`Searching for: ${searchQuery}, Filter: ${filter}`);
    };

    return (
        <div className="row carousel-caption d-flex justify-content-center align-items-center">
            <div className="background-overlay">
                <div className="col text-center text-light p-4 rounded">
                    <h2 className="text-center mb-4">Find Your Dream Property</h2>
                    <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search properties..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                      
                        <div className="col-md-3">
                            <select className="form-select" value={filter} onChange={handleFilterChange}>
                                <option value="all">All Types</option>
                                <option value="buy">Buy</option>
                                <option value="rent">Rent</option>
                                <option value="commercial">Commercial</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary w-100">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PropertySearch;
