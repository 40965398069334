import React from 'react';

const PropertyDetails = () => {
    return (
        <div className="container marketing mb-5">
            {/* Heading Section */}
            <div className="row justify-content-center">
                <div className="col-md-12 heading-section text-center mb-5">
                    <span className="subheading">Property</span>
                    <h2 className="mb-2">Property Details</h2>
                </div>
            </div>

            {/* Services Section */}
            <div className="row">
                {services.map((service, index) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                        <div className="card shadow-sm border-light">
                            <div className="row card-img-top">
                                <div className="col">
                                    <div id={`carouselExample${index}`} className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                                        <div className="carousel-inner">
                                            {service.images.map((image, imgIndex) => (
                                                <div className={`carousel-item ${imgIndex === 0 ? 'active' : ''}`} key={imgIndex}>
                                                    <img src={image} className="d-block w-100" alt={`Image of ${service.title} - Slide ${imgIndex + 1}`} />
                                                </div>
                                            ))}
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExample${index}`} data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target={`#carouselExample${index}`} data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <h5 className="card-title">{service.title}</h5>
                                <p className="card-text">{service.description}</p>
                                <table className="table table-striped table-hover">
                                    <tbody>
                                        <tr>
                                            <th><strong>Area:</strong></th>
                                            <td>{service.area} sq ft</td>
                                        </tr>
                                        <tr>
                                            <th><strong>Price:</strong></th>
                                            <td>₹{service.price}</td>
                                        </tr>
                                        <tr>
                                            <th><strong>Location:</strong></th>
                                            <td>{service.location}</td>
                                        </tr>
                                        <tr>
                                            <th><strong>Type:</strong></th>
                                            <td>{service.type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer text-center">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Sample data for services with additional fields
const services = [
    {
        title: 'Golden Urban House For Sale',
        description: 'Expert guidance on purchasing properties, from finding the perfect match to closing the deal.',
        images: [
            '/assets/Images/Property/property-search.jpg',
            '/assets/Images/Property/carousel2.jpg',
            '/assets/Images/Property/premium-property.jpg',
        ],
        area: '1500',
        price: '45,00,000',
        location: 'Downtown City',
        type: 'Residential'
    },
    {
        title: 'Elegant Suburban Villa For Sale',
        description: 'Professional services to help you sell your property quickly and at the best price.',
        images: [
            '/assets/Images/Property/premium-property.jpg',
            '/assets/Images/Property/property-search.jpg',
            '/assets/Images/Property/carousel2.jpg',
        ],
        area: '1200',
        price: '35,00,000',
        location: 'Uptown Area',
        type: 'Commercial'
    },
    {
        title: 'Modern City Apartment For Sale',
        description: 'Comprehensive property management services to keep your investments in top shape.',
        images: [
            '/assets/Images/Property/car-4.jpg',
            '/assets/Images/Property/car-5.jpg',
            '/assets/Images/Property/car-4.jpg',
        ],
        area: '1800',
        price: '60,00,000',
        location: 'Suburban District',
        type: 'Mixed-use'
    }
];

export default PropertyDetails;
