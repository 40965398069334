import React from 'react'

const About = () => {
    return (
        <>
            <div class="container-fluid p-5 bg-background">
                <div className="row p-5 pb-0">
                    <div className="col p-5 text-center">
                        <h6>Who We are</h6>
                        <h1 class="display-5 fw-bold">About our company</h1>
                        <p class="col-12 fs-4">Utilizing our exceptional experience and knowledge of the luxury waterfront markets,we serve an extensive and elite worldwide client base. </p>
                        {/* <button class="btn btn-primary btn-lg" type="button">Example button</button> */}
                    </div>
                </div>
                <div className="row mb-5 mt-2">
                    <div className="col p-5">
                        <h2 className="featurette-heading">Premium Properties in Top Locations. <span className="text-muted">Experience Luxury Living.</span></h2>
                        <p className="lead">Explore our selection of high-end properties situated in the most desirable locations. Find your dream home today.</p>
                    </div>
                    <div className="col">
                        <div id="carouselExampleDark" class="carousel carousel-dark slide">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-bs-interval="100">
                                    <img src="/assets/Images/Property/premium-property.jpg" class="d-block w-100" alt="images" />
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>First slide label</h5>
                                        <p>Some representative placeholder content for the first slide.</p>
                                    </div>
                                </div>
                                <div class="carousel-item" data-bs-interval="200">
                                    <img src="/assets/Images/Property/premium-property.jpg" class="d-block w-100" alt="images" />
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>Second slide label</h5>
                                        <p>Some representative placeholder content for the second slide.</p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <img src="/assets/Images/Property/premium-property.jpg" class="d-block w-100" alt="images" />
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>Third slide label</h5>
                                        <p>Some representative placeholder content for the third slide.</p>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-0 gx-5 align-items-end">
                        <div class="col-lg-6">
                            <div class="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'slideInLeft;' }}>
                                <h1 class="mb-3">Property Listing</h1>
                                <p><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>But the pain itself was great because of the labor of the rebum. The time for the pain to come is truly the same, so that the diam is just, but the rebum.</font></font></p>
                            </div>
                        </div>
                        <div class="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'slideInRight' }}>
                            <ul class="nav nav-pills d-inline-flex justify-content-end mb-5">
                                <li class="nav-item me-2">
                                    <a class="btn btn-outline-primary active" data-bs-toggle="pill" href="#tab-1">Featured</a>
                                </li>
                                <li class="nav-item me-2">
                                    <a class="btn btn-outline-primary" data-bs-toggle="pill" href="#tab-2">For Sell</a>
                                </li>
                                <li class="nav-item me-0">
                                    <a class="btn btn-outline-primary" data-bs-toggle="pill" href="#tab-3">For Rent</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div id="tab-1" class="tab-pane fade show p-0 active">
                            <div class="row g-4">
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                                    <div className="card">
                                        <div class="property-item rounded overflow-hidden">
                                            <div class="position-relative overflow-hidden">
                                                <a href=""><img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="" /></a>
                                                <div class="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>For Sell</font></font></div>
                                                <div class="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Appartment</div>
                                            </div>
                                            <div class="p-4 pb-0">
                                                <h5 class="text-primary mb-3">$12,345</h5>
                                                <a class="d-block h5 mb-2 card-heading" href="">Golden Urban House For Sell</a>
                                                <p><i class="bi bi-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                            </div>
                                            <div class="d-flex border-top">
                                                <small class="flex-fill text-center border-end py-2">
                                                    <i class="bi bi-ruler"></i> 1000 Sqft
                                                </small>
                                                <small class="flex-fill text-center border-end py-2">
                                                    <i class="bi bi-bed"></i> 3 Bed
                                                </small>
                                                <small class="flex-fill text-center py-2">
                                                    <i class="bi bi-bath"></i> 2 Bath
                                                </small>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-2" class="tab-pane fade show p-0">
                            <div class="row g-4">
                                <div class="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div class="property-item rounded overflow-hidden">
                                            <div class="position-relative overflow-hidden">
                                                <a href=""><img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="" /></a>
                                                <div class="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>For Sell</font></font></div>
                                                <div class="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Appartment</font></font></div>
                                            </div>
                                            <div class="p-4 pb-0">
                                                <h5 class="text-primary mb-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>$12,345</font></font></h5>
                                                <a class="d-block h5 mb-2 card-heading" href=""><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Golden Urban House For Sell</font></font></a>
                                                <p><i class="fa fa-map-marker-alt text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>123 Street, New York, USA</font></font></p>
                                            </div>
                                            <div class="d-flex border-top">
                                                <small class="flex-fill text-center border-end py-2"><i class="fa fa-ruler-combined text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>1000 Sqft</font></font></small>
                                                <small class="flex-fill text-center border-end py-2"><i class="fa fa-bed text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>3 Bed</font></font></small>
                                                <small class="flex-fill text-center py-2"><i class="fa fa-bath text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>2 Bath</font></font></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-3" class="tab-pane fade show p-0">
                            <div class="row g-4">
                                <div class="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div class="property-item rounded overflow-hidden">
                                            <div class="position-relative overflow-hidden">
                                                <a href=""><img class="img-fluid" src="/assets/Images/Property/property-search.jpg" alt="" /></a>
                                                <div class="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>For Sell</font></font></div>
                                                <div class="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Appartment</font></font></div>
                                            </div>
                                            <div class="p-4 pb-0">
                                                <h5 class="text-primary mb-3"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>$12,345</font></font></h5>
                                                <a class="d-block h5 mb-2 card-heading" href=""><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Golden Urban House For Sell</font></font></a>
                                                <p><i class="fa fa-map-marker-alt text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>123 Street, New York, USA</font></font></p>
                                            </div>
                                            <div class="d-flex border-top">
                                                <small class="flex-fill text-center border-end py-2"><i class="fa fa-ruler-combined text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>1000 Sqft</font></font></small>
                                                <small class="flex-fill text-center border-end py-2"><i class="fa fa-bed text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>3 Bed</font></font></small>
                                                <small class="flex-fill text-center py-2"><i class="fa fa-bath text-primary me-2"></i><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>2 Bath</font></font></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container bg-dark p-2">
                    <div class="bg-light rounded p-3">
                        <div class="bg-white rounded p-4" style={{ border: '1px dashed rgba(0, 185, 142, .3)' }}>
                            <div class="row g-5 align-items-center">
                                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                    <img class="img-fluid rounded w-100" src="/assets/Images/Property/property-search.jpg" alt="" />
                                </div>
                                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                    <div class="mb-4">
                                        <h1 class="mb-3">Contact With Our Certified Agent</h1>
                                        <p>Eirmod sed ipsum dolor sit rebum magna erat. Tempor lorem kasd vero ipsum sit sit diam justo sed vero dolor duo.</p>
                                    </div>
                                    <a href="" class="btn btn-primary py-3 px-4 me-2"><i class="fa fa-phone-alt me-2"></i>Make A Call</a>
                                    <a href="" class="btn btn-dark py-3 px-4"><i class="fa fa-calendar-alt me-2"></i>Get Appoinment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About