import React from 'react'

const TypeCarousel = () => {
    return (
        <>
            
        </>
    )
}

export default TypeCarousel